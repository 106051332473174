import React, { useCallback, useRef } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { graphql, useStaticQuery } from 'gatsby';

import { Container } from '../../components/container';
import { Layout } from '../../components/layout';
import { SEO } from '../../components/seo';
import { Service } from '../../components/service';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

import { HeaderImage } from './styles';
import { Promo } from './components/promo';
import { ContactForm } from './components/contact-form';
import { Testimonials } from './components/testimonials';

export const IndexPage: React.FC = () => {
  const { contact } = useSiteMetadata();
  const contactForm = useRef(null);

  const {
    aboutPage,
    promos,
    services,
    testimonials,
  } = useStaticQuery<GatsbyTypes.IndexPageQuery>(graphql`
    query IndexPage {
      aboutPage: contentfulPage(slug: { eq: "home" }) {
        keywords
        title
        subHeaderText
        headerText
        description {
          description
        }
        headerImage {
          fluid(quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        headers {
          label
          text
        }
      }
      promos: allContentfulPromoCallout(sort: { order: ASC, fields: order }) {
        edges {
          node {
            id
            header
            icon
            content {
              content
            }
          }
        }
      }
      services: allContentfulService(sort: { fields: order, order: ASC }) {
        edges {
          node {
            id
            image {
              fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            cost
            serviceName
          }
        }
      }
      testimonials: allContentfulTestimonial(
        sort: { order: ASC, fields: order }
      ) {
        edges {
          node {
            author
            quote {
              quote
            }
            id
          }
        }
      }
    }
  `);

  const handleContactFormSubmit = useCallback(() => {
    if (contactForm.current) {
      const form = (contactForm.current as unknown) as HTMLFormElement;
      form.submit();
    }
  }, []);

  return (
    <Layout>
      <SEO
        title="Home"
        description={aboutPage?.description?.description}
        keywords={aboutPage?.keywords}
      />
      <div className="relative">
        <div className="absolute top-0 left-0 z-10 w-full py-12 text-center font-openSans">
          <div className="max-w-3xl mx-auto">
            <h1 className="mb-4 text-3xl font-semibold text-white uppercase sm:font-light sm:text-7xl">
              {aboutPage?.headerText}
            </h1>
            <h2 className="text-xl font-light text-white capitalize">
              {aboutPage?.subHeaderText}
            </h2>
          </div>
        </div>
        {aboutPage?.headerImage?.fluid && (
          <HeaderImage fluid={aboutPage?.headerImage?.fluid} />
        )}
      </div>
      <div className="px-4 py-20 text-black bg-spring-leaves-100 sm:px-0">
        <Container>
          <div className="mb-12">
            <h1 className="mb-4 text-4xl text-center uppercase">
              {aboutPage?.headers?.find((x) => x?.label === 'promo-h1')?.text}
            </h1>
            <h2 className="text-lg text-center">
              {aboutPage?.headers?.find((x) => x?.label === 'promo-h2')?.text}
            </h2>
          </div>
          <div className="sm:flex sm:items-center sm:justify-between">
            {promos.edges.map(({ node }) => (
              <Promo
                key={node.id}
                header={node.header}
                content={node.content?.content}
                icon={node.icon as IconProp}
              />
            ))}
          </div>
        </Container>
      </div>
      <div className="py-10 lg:py-20">
        <Container>
          <div className="lg:items-center lg:justify-between lg:flex">
            {services.edges.map(({ node }) => (
              <Service
                key={node.id}
                cost={node.cost}
                serviceName={node.serviceName}
                image={node.image?.fluid}
              />
            ))}
          </div>
        </Container>
      </div>
      <div className="px-4 py-20 bg-spring-leaves-100 xl:px-0">
        <Container>
          <Testimonials testimonials={testimonials} />
        </Container>
      </div>
      <Container>
        <div className="px-4 py-10 xl:px-0 lg:grid lg:grid-cols-2 lg:py-20">
          <div className="mx-auto">
            <h1 className="mb-4 text-5xl font-light uppercase">Contact Me</h1>
            <div className="text-sm sm:px-4">
              <h2 className="mb-4">Cleveland, Ohio</h2>
              <h2 className="mb-4">
                <a className="underline" href={`mailto:${contact?.email}`}>
                  {contact?.email}
                </a>
              </h2>
              <h2 className="mb-4">
                <a className="underline" href={`tel:${contact?.phone}`}>
                  {contact?.phone}
                </a>
              </h2>
            </div>
          </div>
          <ContactForm ref={contactForm} onSubmit={handleContactFormSubmit} />
        </div>
      </Container>
    </Layout>
  );
};
