import React from 'react';

export const TextArea: React.FC<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({ className, ...props }) => (
  <textarea
    className={`px-4 py-2 border border-black ${className}`}
    {...props}
  />
);
