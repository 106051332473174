import React from 'react';

import { useForm } from 'react-hook-form';

import { Input } from '../../../../components/input';
import { TextArea } from '../../../../components/textarea';

type ContactFormProps = {
  onSubmit: () => void;
};

export const ContactForm = React.forwardRef<HTMLFormElement, ContactFormProps>(
  ({ onSubmit }, ref) => {
    const { handleSubmit, register } = useForm();

    return (
      <form
        name="contact"
        ref={ref}
        method="POST"
        action="/contact-form-success"
        data-netlify="true"
        netlify-honeypot="true"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div className="flex flex-col">
          <div className="mb-4">
            <label htmlFor="contact-name" className="sr-only">
              Name
            </label>
            <Input
              {...(register('name'), { required: true })}
              className="w-full"
              id="contact-name"
              type="text"
              placeholder="Name"
            ></Input>
          </div>
          <div className="grid grid-cols-2 mb-4">
            <div className="mr-2">
              <label htmlFor="contact-email" className="sr-only">
                Email
              </label>
              <Input
                {...(register('email'), { required: true })}
                className="w-full"
                id="contact-email"
                type="email"
                placeholder="Email"
              />
            </div>
            <div className="ml-2">
              <label htmlFor="contact-phone" className="sr-only">
                Phone
              </label>
              <Input
                {...(register('phone'), { required: true })}
                className="w-full"
                id="contact-phone"
                type="phone"
                placeholder="Phone"
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="contact-address" className="sr-only">
              Address
            </label>
            <Input
              {...(register('address'), { required: true })}
              className="w-full"
              id="contact-address"
              type="text"
              placeholder="Address"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="contact-subject" className="sr-only">
              Subject
            </label>
            <Input
              {...(register('subject'), { required: true })}
              className="w-full"
              id="contact-subject"
              type="text"
              placeholder="Subject"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="contact-message" className="sr-only">
              Message
            </label>
            <TextArea
              {...(register('message'), { required: true })}
              className="w-full"
              id="contact-message"
              rows={6}
              placeholder="Type your message here..."
            />
          </div>
        </div>
        <button
          className="w-full py-4 text-white bg-spring-leaves-500"
          type="submit"
        >
          Submit
        </button>
      </form>
    );
  }
);
