import React from 'react';

import { Content } from './styles';

type TestimonialProps = {
  author?: string;
};

export const Testimonial: React.FC<TestimonialProps> = ({
  children,
  author,
}) => (
  <Content className="items-center justify-center hidden px-16 mb-12 text-center transition-all duration-1000 ease-in-out transform slide">
    <p className="mb-6 text-xl leading-relaxed font-athiti">"{children}"</p>
    <div className="text-lg">{author}</div>
  </Content>
);
