import React, { useCallback, useEffect } from 'react';
import { Testimonial } from './components/testimonial';

type TestimonialsProps = Pick<GatsbyTypes.IndexPageQuery, 'testimonials'>;

export const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {
  // initial setup of slide classes
  useEffect(() => {
    const slides = document.querySelectorAll('.slide');
    slides.forEach((slide, idx) => {
      slide.classList.remove('translate-x-full');
      slide.classList.remove('-translate-x-full');
      slide.classList.remove('hidden');
      slide.classList.add('flex');
      slide.classList.add('flex-col');

      if (idx === 0) {
        slide.classList.add('translate-x-0');
        return;
      }

      slide.classList.add('translate-x-full');
    });
  }, []);

  const handlePrev = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      const activeSlide = document.querySelector('.slide.translate-x-0');
      let previousSlide = activeSlide?.previousElementSibling;

      if (previousSlide?.tagName.toLowerCase() === 'button') {
        return;
      }

      activeSlide?.classList.remove('translate-x-0');
      activeSlide?.classList.add('translate-x-full');

      previousSlide?.classList.remove('-translate-x-full');
      previousSlide?.classList.add('translate-x-0');
    },
    []
  );

  const handleNext = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const activeSlide = document.querySelector('.slide.translate-x-0');
      let nextSlide = activeSlide?.nextElementSibling;

      if (nextSlide?.tagName.toLowerCase() === 'button') {
        return;
      }

      activeSlide?.classList.remove('translate-x-0');
      activeSlide?.classList.add('-translate-x-full');

      nextSlide?.classList.remove('translate-x-full');
      nextSlide?.classList.add('translate-x-0');
    },
    []
  );

  return (
    <div className="relative flex items-center justify-center overflow-hidden h-80">
      <button
        className="absolute left-0 z-10 flex items-center justify-center w-16 h-16 bottom-1/2"
        onClick={handlePrev}
      >
        &#x276E;
      </button>
      {testimonials.edges.map(({ node }) => (
        <Testimonial key={node.id} author={node.author}>
          {node.quote?.quote}
        </Testimonial>
      ))}
      <button
        className="absolute right-0 z-10 flex items-center justify-center w-16 h-16 bottom-1/2"
        onClick={handleNext}
      >
        &#x276F;
      </button>
    </div>
  );
};
