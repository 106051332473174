import React from 'react';

import { faBrain, faStar } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type PromoProps = {
  icon?: IconProp;
  header?: string;
  content?: string;
};

export const Promo: React.FC<PromoProps> = ({ icon, header, content }) => (
  <div className="max-w-sm mx-auto mb-4 text-center sm:mb-0">
    {icon && (
      <div className="mb-4 text-4xl">
        <FontAwesomeIcon icon={icon === 'brain' ? faBrain : faStar} />
      </div>
    )}
    <h1 className="mb-4 text-2xl uppercase">{header}</h1>
    <p className="text-sm leading-loose">{content}</p>
  </div>
);
